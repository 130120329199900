/* Block - table.
 *
 * Imports the block css.
 * Contains block-specific functionality.
 *
 * @file single
 *
 */

(function($, window, document) { // jQuery wrapper


    // Page Functions
    window.ProductTables = {
        tables : document.querySelectorAll(".product"),
        // Classes
        classes : {
            scrollBarActive: "scrollbar-active"
        },

        // FUNCTIONS 
        run : function(){
            window.ProductTables.setupScrollBars();
        },

        elementExists : function(element) {
            return typeof element !== "undefined" && element != null;
        },


        // Setup all table elements on the page
        setupScrollBars : function() {
            if (window.ProductTables.tables.length > 0) {
                window.ProductTables.tables.forEach( function(tableElement){
                    window.ProductTables.setupScrollBarDOM(tableElement);
                    window.ProductTables.setupScrollBar(tableElement);
                });
            }
        },

        // Setup a single table
        setupScrollBarDOM : function(tableElement) {
            $(tableElement).append($('<div class="ecco-scroll--track"><div class="ecco-scroll--handle"></div></div>'));
        },
         
        // Setup a single table
        setupScrollBar : function(tableElement) {
            console.log(tableElement);
            var table = {
                container: $(".dataTables_wrapper").get[0],
                scrollbarTrack: tableElement.querySelector(
                    ".ecco-scroll--track"
                ),
                scrollbarHandle: tableElement.querySelector(
                    ".ecco-scroll--handle"
                ),
                // Variables for dragging
                isDown: false,
                startX: 0,
                scrollLeft: 0,
                scrollPercent: 0,
                // Variables for dragging handle
                handleIsDown: false
            };

            // Check to make sure elements exist
            if (window.ProductTables.elementExists(table.container)) {
                window.ProductTables.updateScrollBarClasses(table);
                window.ProductTables.watchtableScroll(table);
                window.ProductTables.maketableDraggable(table);
                window.ProductTables.makeScrollBarClickable(table);
                window.ProductTables.makeScrollHandleDraggable(table);

                window.ProductTables.setScrollPosition(table);
                window.ProductTables.updateOnResize(table);
            }
        },
        // update the classes for the scrollbar
        updateScrollBarClasses : function (table) {
            if (window.ProductTables.isScrollBarRequired(table.container)) {
                table.container.classList.add(window.ProductTables.classes.scrollBarActive);
            } else {
                table.container.classList.remove(window.ProductTables.classes.scrollBarActive);
            }
        },
        // calculates if a scrollbar is required
        isScrollBarRequired : function (tableContainer) {
            return tableContainer.scrollWidth > tableContainer.clientWidth;
        },
        
        // Watches the scrollbar for scrolling
        watchtableScroll : function (table) {
            table.container.addEventListener("scroll", function(event){
                if (!table.handleIsDown) {
                    window.ProductTables.setScrollPosition(table);
                    // updateScrollPercentage(table);
                    // repositionScrollHandle(table);
                }
            });
        },
        // resizeListener : function (myresizeFunction) {
        //     // Setup a timer
        //     var timeout;
        //     // Listen for resize events
        //     window.addEventListener(
        //         "resize",
        //         function(event) {
        //             // If there's a timer, cancel it
        //             if (timeout) {
        //                 window.cancelAnimationFrame(timeout);
        //             }
        //             // Setup the new requestAnimationFrame()
        //             timeout = window.requestAnimationFrame(function() {
        //                 // Run our resize functions (Your code here)
        //                 myresizeFunction(event);
        //             });
        //         },
        //         false
        //     );
        // },
        
        // Updates the scroll percentage
        updateScrollPercentage : function (table) {
            table.scrollPercent =
                (table.container.scrollLeft /
                    (table.container.scrollWidth - table.container.clientWidth)) *
                100;
            return table.scrollPercent;
        },
        // Repositions the handle based on scroll percent
        repositionScrollHandle : function (table) {
            table.scrollbarHandle.style.left = table.scrollPercent + "%";
        },
        
        // make scroll bar clickable
        makeScrollBarClickable : function (table) {
            table.scrollbarTrack.addEventListener("click", function(event){
                var scrollBarWidth = table.scrollbarTrack.clientWidth;
                var scrollBarBox = table.scrollbarTrack.getBoundingClientRect();
                var xPosition = event.clientX - scrollBarBox.left;
                var percentToScroll = xPosition / scrollBarWidth;
                // console.log(event.clientX / table.scrollbarTrack.offsetWidth);
                window.ProductTables.scrollContainer(table, percentToScroll);
            });
        },
        // Scrolls the container a specific amount
        scrollContainer : function (table, percentToScroll) {
            var scrollDistance =
                table.container.scrollWidth - table.container.clientWidth;
            var distanceToScroll = scrollDistance * percentToScroll;
            table.container.scrollLeft = distanceToScroll;
        },
        
        // Handles functionality to make table draggable
        maketableDraggable : function (table) {
            alert('down');
            console.log(table);
            table.container.addEventListener("mousedown", function(e) {
                alert('down');
                table.isDown = true;
                table.container.classList.add("active");
                table.startX = e.pageX - table.container.offsetLeft;
                table.scrollLeft = table.container.scrollLeft;
            });
            table.container.addEventListener("mouseleave", function(){
                table.isDown = false;
                table.container.classList.remove("active");
            });
            table.container.addEventListener("mouseup", function(){
                table.isDown = false;
                table.container.classList.remove("active");
            });
            table.container.addEventListener("mousemove", function(e){
                if (!table.isDown) {
                    return;
                }

                e.preventDefault();
                var x = e.pageX - table.container.offsetLeft;
                var walk = (x - table.startX) * 3; // scroll-fast
                table.container.scrollLeft = table.scrollLeft - walk;
            });
        },
        
        // Handles functionality to make handle draggable
        makeScrollHandleDraggable : function (table) {
            table.scrollbarHandle.addEventListener("mousedown", function(e) {
                window.ProductTables.startHandleDrag(e, table);
            });
            table.scrollbarHandle.addEventListener("mouseleave", function(e){
                window.ProductTables.doHandleDrag(e, table);
            });
            document.addEventListener("mouseup", function(e){
                window.ProductTables.endHandleDrag(e, table);
            });
            document.addEventListener("mousemove", function(e){
                window.ProductTables.doHandleDrag(e, table);
            });
        
            table.scrollbarHandle.addEventListener("touchstart", function(e){
                window.ProductTables.startHandleDrag(e, table);
            });
            table.scrollbarHandle.addEventListener("touchend", function(e){
                window.ProductTables.endHandleDrag(e, table);
            });
            document.addEventListener("touchcancel", function(e){
                window.ProductTables.endHandleDrag(e, table);
            });
            document.addEventListener("touchmove", function(e){
                window.ProductTables.doHandleDrag(e, table);
            });
        },
        
        // Start handle Drag
        startHandleDrag : function (e, table) {
            e.preventDefault();
            table.handleIsDown = true;
            table.scrollbarHandle.classList.add("active");
        },
        // End handle Drag
        endHandleDrag : function (e, table) {
            e.preventDefault();
            table.handleIsDown = false;
            table.scrollbarHandle.classList.remove("active");
        },
        // Do handle drag
        doHandleDrag : function (event, table) {
            var tableBox = table.scrollbarHandle.getBoundingClientRect();
            event.preventDefault();
            if (!table.handleIsDown) {
                return;
            }
        
            var scrollBarWidth = table.scrollbarTrack.clientWidth;
            var scrollBarBox = table.scrollbarTrack.getBoundingClientRect();
            var xPosition = 0;
        
            if (event.clientX) {
                xPosition = event.clientX - scrollBarBox.left;
            } else {
                // touchmove - assuming a single touchpoint
                xPosition = event.changedTouches[0].clientX - scrollBarBox.left;
            }
        
            var percentToScroll = xPosition / scrollBarWidth;
            if (percentToScroll <= 1 && percentToScroll >= 0) {
                window.ProductTables.scrollContainer(table, percentToScroll);
                // add draggableRect.width draggableRect.height accoints for
                table.scrollbarHandle.style.left = percentToScroll * 100 + "%";
            }
        },
        
        // Set the position of the scroll handle
        setScrollPosition : function (table) {
            window.ProductTables.updateScrollPercentage(table);
            window.ProductTables.repositionScrollHandle(table);
        },
        // Update the scrollbar when screen resizes
        updateOnResize : function (table) {
            //  Reset the height of the splider on resize
            window.ProductTables.resizeListener( function(event) {
                window.ProductTables.setScrollPosition(table);
            });
        },
    };
}(window.jQuery, window, document)); // End of jQuery wrapper