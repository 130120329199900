/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // window.PageFunctions.smoothScroll();
                // // JavaScript to be fired on all pages
                // window.MenuFunctions.setupMenuToggle();
                // window.MenuFunctions.setupSubMenuToggle();

                // mobile menu
                $('.j-header--trigger').on('click',function(){
                    $(this).toggleClass('active');
                    $('.e-mobile').toggleClass('active');
                    $('body').toggleClass('custom-overflow');
                });

                $('#mobile-menu .menu-item-has-children > a').append('<span class="menu-toggle"></span>');

                $('.menu-toggle').on('click',function(e){
                    e.preventDefault();
                    $(this).parent().siblings('.sub-menu').slideToggle();
                });

                $('.no-link > a').on('click',function(e){
                    e.preventDefault();
                });

                // accordion hide/reveal
                $('.j-accordion--title').on('click',function(){
                    $(this).siblings('.j-accordion--reveal').slideToggle();
                });

                // content slider
                var $slickElement = $('.j-slider--slick');
                var $status = $('.e-slider--mobile-nav');

                $slickElement.on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
                    //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
                    var i = (currentSlide ? currentSlide : 0) + 1;
                    $(this).find($status).html('<p class="current-of-total"><span class="current">' + i + '</span>' + ' <span class="of">/</span> ' + '<span class="total">' + slick.slideCount + '</span></p>');
                });

                $slickElement.each(function(index) {
                    $(this).attr('data-slider',index);

                    $(this).slick({
                        'slide' : '.e-slide',
                        'slidesToShow' : 1,
                        'slidesToScroll' : 1,
                        'infinite' : true,
                        'speed' : 300,
                        'dots' : true,
                        'arrows' : true,
                        'autoplay' : false,
                        'fade' : true,
                        customPaging: function(slider, i) {
                            return $(slider.$slides[i]).attr('title');
                        },
                        'responsive' : [
                            {
                                'breakpoint' : 768,
                                'settings' : {
                                    'dots' : false,
                                    'adaptiveHeight' : true,
                                }
                            }
                        ]
                    });
                });


                

            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
                // window.ProductTables.run();
                window.ProductFunctions.setup();
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'single_product': {
            init: function () {
                // JavaScript to be fired on the single product page
                var $productSlick = $('.j-product--slick');
                var $productStatus = $('.e-product--slick--mobile-nav');

                // here we look and see if there's a hash in the url.
                // we grab it, and remove the # so we can check it against a data attribute
                var page_hash = window.location.hash;
                page_hash = page_hash.replace('#','');
                var slide_to_go_to = $(".e-p-slide[data-hash='" + page_hash + "']").index();

                // this is the setup for the mobile nav of the tabs
                $productSlick.on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
                    //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
                    var i = (currentSlide ? currentSlide : 0) + 1;
                    $(this).find($productStatus).html('<p class="current-of-total"><span class="current">' + i + '</span>' + ' <span class="of">/</span> ' + '<span class="total">' + slick.slideCount + '</span></p>');
                });

                // single product tabs
                $productSlick.slick({
                    'slide' : '.e-p-slide',
                    'slidesToShow' : 1,
                    'slidesToScroll' : 1,
                    'infinite' : true,
                    'speed' : 300,
                    'dots' : true,
                    'arrows' : false,
                    'autoplay' : false,
                    'fade' : true,
                    'adaptiveHeight' : true,
                    'draggable' : false,
                    customPaging: function(slider, i) {
                        return '<span>' + $(slider.$slides[i]).attr('title') + '</span>';
                    },
                    'responsive' : [
                        {
                            'breakpoint' : 768,
                            'settings' : {
                                'arrows' : true,
                            }
                        }
                    ]
                });

                // this is where we set slide hashes; when the slide is interacted with
                $productSlick.on('afterChange',function(event, slick, currentSlide){
                    var this_slide = $(slick.$slides[currentSlide]);
                    var hash_url = this_slide.data('hash');
                    window.location.hash = hash_url;
                });

                // if there is a hash in the url, let's go to the slide it's associated with
                if ( window.location.hash ) {
                    $productSlick.slick('slickGoTo',slide_to_go_to);
                }
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

