(function($, window, document) { // jQuery wrapper

    // Product Functions
    window.ProductFunctions = {
        addAllButton: "",
        products: "",

        setup : function (){

            window.ProductFunctions.setQuoteCount();
            
            if(jQuery('body').hasClass('tax-product_cat')){
                window.ProductFunctions.addAllButton = jQuery('.j-products--add-all');
                window.ProductFunctions.currentProductRows = jQuery('.slick-active .variations-table-row');
                window.ProductFunctions.productRows = jQuery('.variations-table-row');
                window.ProductFunctions.setupSlick();

                window.ProductFunctions.eventHandlers();
                window.ProductFunctions.setupQuantityControls();
            }else if(jQuery('body').hasClass('woocommerce-cart')){
                window.ProductFunctions.eventHandlers();
                window.ProductFunctions.setupQuantityControls();
            }
        },

        setQuoteCount: function () {
            var navItem = jQuery('.e-header--view-quote > .e-header--view-quote--count').first();
            var mobileNavItem = jQuery('#mobile-menu > .nav-checkout > a').first();
            var text = mobileNavItem.text().split('(')[0];
            
            jQuery.post(myAjax.ajaxurl, {
                'action': 'cart_count'
            }, function(response) {
                navItem.html(' (' + response + ')');
                mobileNavItem.text(text + ' (' + response + ')');
            });
        },

        eventHandlers : function(){
            jQuery('.variations-table-value-qt').on('change', 'input', function(index){
                window.ProductFunctions.checkAddAllToQuoteStatus();
            });
            window.ProductFunctions.addAllButton.on('click', function(index){
                if( ! $(this).hasClass('disabled') && ! $(this).hasClass('is_loading') ){
                    window.ProductFunctions.addProductsToCart(function () {
                        window.ProductFunctions.resetProducts();

                        window.ProductFunctions.addAllButton.html('Success!');

                        setTimeout(function () {
                            window.ProductFunctions.addAllButton.html('Add All to Quote');
                        }, 3000);
                    });
                }
            });
            jQuery('.woocommerce-variation-add-to-cart button.button').on('click', function (index) {
                if( ! $(this).hasClass('disabled') && ! $(this).hasClass('is_loading') ){
                    product = $(this).closest('.variations-table-row');

                    if (product.find('.input-text.qty').val() <= 0) {
                        return false;
                    }

                    products = [{
                        'sku' : product.find('[data-th="SKU"]').text(),
                        'quantity' : product.find('.input-text.qty').val(),
                        'product_id' : product.find('[name="product_id"]').val(),
                        'variation_id' : product.find('[name="variation_id"]').val(),
                    }];

                    $(this).addClass('disabled');
                    $(this).addClass('is_loading');
                    $(this).html('Adding');
                    
                    window.ProductFunctions.addProducts(products, function () {
                        button = product.find('.variations_button > button.button');
                        product.find('.input-text.qty').val(0);
                        window.ProductFunctions.updateQuantityClass(product.find('.input-text.qty'), 0);
                        window.ProductFunctions.checkAddAllToQuoteStatus();

                        button.removeClass('disabled');
                        button.removeClass('is_loading');
                        button.html('Success!');

                        setTimeout(function () {
                            button.html('Add to Quote');
                        }, 3000);
                    });
                }
            });
            $('body').on('added_to_cart', function(fragments, hash, test, button){
                $(button).text('Success').removeClass('loading').removeClass('added');

                window.ProductFunctions.resetProducts();

                setTimeout(function(){
                    $(button).text('Add to Quote');
                }, 3000 );
            });
        },

        // Add Product
        productHasQuantity : function () {
            var result = false;
            jQuery('.variations-table-value-qt input').each(function(index){
                if( $(this).val() > 0 ){
                    result = true;
                }
            });
            return result;
        },

        // Check if Add All To Quote button should be enabled
        checkAddAllToQuoteStatus: function(){
            if( window.ProductFunctions.productHasQuantity() ) {
                window.ProductFunctions.addButtonOn();
            } else{
                window.ProductFunctions.addButtonOff();
            }
        },

        // Enable /  Disable the Add All To Quote button
        addButtonToggle : function (){
            window.ProductFunctions.addAllButton.toggleClass('disabled');
        },
        addButtonOn : function (){
            window.ProductFunctions.addAllButton.removeClass('disabled');
        },
        addButtonOff : function (){
            window.ProductFunctions.addAllButton.addClass('disabled');
        },

        // Add product to cart
        addProducts: function (products, callback){
            window.ProductFunctions.addAllButton.addClass('is_loading');
            jQuery.ajax({
                type : "post",
                dataType : "json",
                url : myAjax.ajaxurl,
                data : {action: "ecco_add_to_cart", products : products},
                success: function(response) {    
                    window.ProductFunctions.addAllButton.removeClass('is_loading');
                    callback();
                    window.ProductFunctions.setQuoteCount();
                }
             });
        },


        addProductsToCart: function(callback) {
            window.ProductFunctions.addProducts( 
                window.ProductFunctions.gatherProducts(), 
                callback
            );
        },

        // Sort Products
        gatherProducts: function(){
            var products = false;
            window.ProductFunctions.currentProductRows = jQuery('.slick-active .variations-table-row');
            if(window.ProductFunctions.currentProductRows){
                products = [];
                window.ProductFunctions.currentProductRows.each(function(index){
                    var product = $(this);
                    var qty = product.find('.input-text.qty').val();
                    if(qty > 0){
                        products.push({
                            'sku' : product.find('[data-th="SKU"]').text(),
                            'quantity' : qty,
                            'product_id' : product.find('[name="product_id"]').val(),
                            'variation_id' : product.find('[name="variation_id"]').val(),
                        });
                    }
                });
            }
            return products;
        },
        resetProducts: function(){
            if(window.ProductFunctions.currentProductRows){
                window.ProductFunctions.currentProductRows.each(function(index){
                    var product = $(this);
                    var input = product.find('.input-text.qty');
                    if( input.val() > 0){
                        input.val(0);
                        window.ProductFunctions.updateQuantityClass(input, 0);
                        window.ProductFunctions.checkAddAllToQuoteStatus();
                    }
                });
            }
        },
        

        setupSlick: function(){

            $('.ecco-product-container__posts').each(function(){
                var _this = $(this);
                var slider = _this.find('.j-ecco-products');

                var params = (new URL(document.location)).searchParams;

                if (params.get('product_id') !== null) {
                    slider.on('init', function (event, slick) {
                    
                        var product = $('[data-product-id="' + params.get('product_id') + '"]');

                        if (product.length) {
                            $([document.documentElement, document.body]).animate({
                                scrollTop: product.offset().top - 50
                            }, 500);
                        }
                    });
                }

                var starting_slide = slider.data('active-slide');                
                var slick = slider.slick({
                    arrows: false,
                    draggable: false,
                    touchMove: false,
                    adaptiveHeight: true,
                    fade: true,
                    dots: true,
                    initialSlide: starting_slide,
                    customPaging: function(slider, i) {
                        return '<button class="ecco-product-filters--button">' + $(slider.$slides[i]).find('.ecco-product-tables--title').text() + '</button>';
                    }
                });
                _this.find('.ecco-product-tables--select').on('change',function(index){
                    slick.slick('slickGoTo', $(this).val());
                });
            });
            
        },

        // Button Quantities
        updateQuantityDom : function(){
            jQuery('<div class="quantity-button quantity-up">+</div><div class="quantity-button quantity-down">-</div>').insertAfter('.quantity .qty');
        },
        setupQuantityControls : function(){
            window.ProductFunctions.updateQuantityDom();
            window.ProductFunctions.productRows.find('.quantity').each(function() {
                var spinner = jQuery(this),
                    input = spinner.find('input[type="number"]'),
                    btnUp = spinner.find('.quantity-up'),
                    btnDown = spinner.find('.quantity-down'),
                    min = input.attr('min'),
                    max = input.attr('max');
            
                btnUp.click(function() {
                    var oldValue = parseFloat(input.val());
                    var newVal = 0;
                    if (max && oldValue >= max) {
                        newVal = oldValue;
                    } else {
                        newVal = oldValue + 1;
                    }
                    input = spinner.find("input");
                    input.val(newVal);

                    window.ProductFunctions.updateQuantityClass(input, newVal);
                     
                    spinner.find("input").trigger("change");
                    
                });
            
                btnDown.click(function() {
                    var oldValue = parseFloat(input.val());
                    var newVal = 0;

                    if (oldValue <= min) {
                        newVal = oldValue;
                    } else {
                        newVal = oldValue - 1;
                    }

                    input = spinner.find("input");
                    input.val(newVal);

                    window.ProductFunctions.updateQuantityClass(input, newVal);

                    spinner.find("input").trigger("change");
                    
                });
        
            });
                

        },
        updateQuantityClass : function(input, value){
            if( value > 0 ) {
                input.addClass('has-quantity');
            } else {
                input.removeClass('has-quantity');
            }
        }
    };

}(window.jQuery, window, document)); // End of jQuery wrapper

